html,
body {
  padding: 0;
  margin: 0;
}
button:focus,
a:focus {
  outline: 0 !important;
}

*:focus {
  outline: 0 !important;
}
/* .chakra-tabs__tab {
  background: #f7f7f7;
  border-radius: 0;
  padding: 16px 0px !important;
  color: #969696;
  font-size: 6px;
} */

.slick-list,
.slick-track,
.slick-slide,
.slick-slide div:first-child,
.h-100 {
  height: 100%;
}

.wo-banner {
  min-height: 480px;
}
